<template>
  <div>
    <div class="authorization">
      <div class="row authorization-content">
        <div class="col-12 authorization-content-card">
          <div class="row justify-content-center">
            <div class="col-11 col-md-auto p-0 ">
              <AccountsAuthorizaiton v-if="this.$route.query.account" />
              <AccountsLogin class="" v-else />
              <div class="row justify-content-between ">
                <div class="col-auto col-md-auto">
                  <Languages variant='link text-muted p-0' />
                </div>
                <div class="col-auto col-md-auto">
                  <div class="row justify-content-center">
                    <div class="col-auto">
                      <p>
                        <router-link class="text-muted f-w-500" to="/accounts/help" v-text="$t('general.help')">
                        </router-link>
                      </p>
                    </div>
                    <div class="col-auto">
                      <p>
                        <router-link class="text-muted f-w-500" to="/accounts/privacy" v-text="$t('general.privacy')">
                        </router-link>
                      </p>
                    </div>
                    <div class="col-auto">
                      <p>
                        <router-link class="text-muted f-w-500" to="/accounts/conditions"
                          v-text="$t('general.conditions')"> </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AccountsRecoveryPassword/>
  </div>
</template>

<script>
  import AccountsLogin from "./components/login-form.vue";
  import AccountsAuthorizaiton from "./components/login-authorization.vue";
  import AccountsRecoveryPassword from "./components/login-recovery-password-modal.vue";
  import Languages from "@/components/Languages";
  export default {
    components: {
      AccountsLogin,
      AccountsAuthorizaiton,
      AccountsRecoveryPassword,
      Languages,
    },
    data() {
      return {
        user: {
          email: null,
          password: null,
          siteId: null,
          to: null,
        },
        emailConfirmation: false,
        validatedEmail: false,
        errors: {
          emailPass: false,
          account: false,
          emailConfirmation: false,
        }
      }
    },
    methods: {},
    beforeMount() {},
    mounted() {

    }
  }
</script>

<style lang="scss">
  .authorization {
    background-color: black;
    padding-top: 25vh;

    .authorization-content {
      min-height: 75vh;
      background-color: white;

      .authorization-content-card {
        .card {
          min-height: 500px;
          width: 450px;
        }
        transform: translateY(-10vh);

        @media only screen and (max-width: 767px) {
          .card {
            min-height: 500px;
            width: 100%;
          }
          transform: translateY(-15vh);
        }
      }
      .authorization-content-message {
        min-height: 25vh;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

   @media only screen and (max-width: 767px) {
    .grecaptcha-badge{
      opacity: 0;
    }
    .authorization {
      padding-top: 0px;

      .authorization-content {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .authorization-content-card {
          .card {
            min-height: 400px;
            width: 100%;
            border: none;
            box-shadow: 0px 0px 0px white !important;
          }
          transform: translateY(-0vh);
        }

      }
    }
  }

</style>